<template>
    <v-select
        @change="config.change && config.change($event)"
        :disabled="config.disabled"
        :dense="config.dense"
        :multiple="config.multiple"
        v-model="model[config.key]"
        :items="config.items"
        :return-object="config.returnObject"
        :label="config.label"
        :placeholder="config.placeholder"
        :item-value="config.itemValue"
        :item-text="config.itemText"
        :required="config.required"
        :clearable="config.clearable"
        :rules="config.rules"></v-select>
</template>

<script>
export default {
    name: "FormSelect",
    props: ['config', 'model', 'errors'],
}
</script>
